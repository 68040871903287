import React from "react";
import Image from "gatsby-image";
import { graphql, Link } from "gatsby";
import { Row, Col, Card, CardTitle, CardImgOverlay } from "reactstrap";
import Section from "../section";

import style from "./card.module.css";

const ArtistModule = props => (
  <Section title="Artists" id="artists" className="artists-container">
    <Row>
      {props.data.map(({ node }) => (
        <Col xs="12" md="6" lg="4" className="mb-4" key={node.id}>
          <Link to={node.fields.slug}>
            <Card>
              <Image
                className={style.image}
                fluid={
                  node.frontmatter.featureImage.image.childImageSharp.fluid
                }
              />
              <CardImgOverlay className={`d-flex ` + style.cardImgOverlay}>
                <CardTitle
                  tag="p"
                  className={`h2 mt-auto ` + style.textOverlay}
                >
                  <span className={style.cardOverlayBackground}>
                    {node.frontmatter.name}
                  </span>
                </CardTitle>
              </CardImgOverlay>
            </Card>
          </Link>
        </Col>
      ))}
    </Row>
  </Section>
);

export const query = graphql`
  fragment ArtistInfo on MarkdownRemark {
    id
    fields {
      slug
    }
    frontmatter {
      title
      name
      date
      featureImage {
        image {
          childImageSharp {
            fluid(maxWidth: 400, maxHeight: 400) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
  }
`;

export default ArtistModule;
