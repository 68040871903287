import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/layout";

import WelcomeSection from "../components/modules/welcome";
import ArtistsSection from "../components/modules/artists";
import NewsSection from "../components/modules/news";
import ContactSection from "../components/modules/contact";
import SEO from "../components/SEO";

export default ({ data }) => (
  <Layout>
    <SEO
      title="Frontpage"
      seoTitle="Eingang Bookings"
      description="The Eingang Bookings agency online frontpage. Book electronic music artists from Europe."
    />
    <WelcomeSection />
    <ArtistsSection data={data.artists.edges} />
    <NewsSection data={data.news.edges} />
    <ContactSection />
  </Layout>
);

export const query = graphql`
  query IndexQuery {
    artists: allMarkdownRemark(
      filter: {fileAbsolutePath: {regex: "/(\/content\/artists)/.*\\.md$/"}}
      sort: { fields: [frontmatter___name], order: ASC }
    ) {
      edges {
        node {
          ...ArtistInfo
        }
      }
    }
    news: allMarkdownRemark(
      filter: {fileAbsolutePath: {regex: "/(\/content\/news)/.*\\.md$/"}}
      sort: { fields: [frontmatter___modifyDate], order: DESC }
      limit: 6
    ) {
      edges {
        node {
          ...NewsInfo
        }
      }
    }
  }
`;
