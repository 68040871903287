import { useStaticQuery, graphql } from "gatsby";

const useWelcomeHtml = () => {
  const { markdownRemark } = useStaticQuery(
    graphql`
      query {
        markdownRemark(
          fileAbsolutePath: { regex: "/content/about/welcome.md$/" }
        ) {
          html
        }
      }
    `
  );
  return markdownRemark.html;
};

export default useWelcomeHtml;
