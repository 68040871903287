import React from "react";
import { Row, Col } from "reactstrap";
import Section from "../section";
import useWelcomeHtml from "../../hooks/useWelcomeHtml";

export default () => {
  const html = useWelcomeHtml();

  return (
    <Section className="welcome-container" id="welcome">
      <Row>
        <Col>
          <div dangerouslySetInnerHTML={{ __html: html }} />
        </Col>
      </Row>
    </Section>
  );
};
