import React from "react";
import Image from "gatsby-image";
import { graphql, Link } from "gatsby";
import { Row, Col, Card, CardTitle, CardImgOverlay } from "reactstrap";
import Section from "../section";

import styles from "./card.module.css";

const NewsItem = props => (
  <Col xs="12" md="6" lg="4" className="mb-4" key={props.key}>
    <Link to={props.data.fields.slug}>
      <Card>
        <Image
          className={styles.image}
          fluid={
            props.data.frontmatter.featureImage.image.childImageSharp.fluid
          }
        />
        <CardImgOverlay className={`d-flex ` + styles.cardImgOverlay}>
          <CardTitle tag="p" className={`h2 mt-auto ` + styles.textOverlay}>
            <span className={styles.cardOverlayBackground}>
              {props.data.frontmatter.title}
            </span>
          </CardTitle>
        </CardImgOverlay>
      </Card>
    </Link>
  </Col>
);

const NewsModule = props => (
  <Section title="News" id="news" className="news-container">
    <Row>
      {props.data.map(({ node }) => (
        <NewsItem key={node.id} data={node} />
      ))}
    </Row>
    <Row className="">
      <Col>
        <Link className={`font-size: 1.1rem;`} to="/news">
          Older posts
        </Link>
      </Col>
    </Row>
  </Section>
);

export const query = graphql`
  fragment NewsInfo on MarkdownRemark {
    id
    fields {
      slug
    }
    frontmatter {
      title
      date(formatString: "MMMM Do, YYYY")
      modifyDate(formatString: "MMMM Do, YYYY")
      featureImage {
        image {
          childImageSharp {
            fluid(maxWidth: 400, maxHeight: 400) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
  }
`;

export default NewsModule;
