import React from "react";
import { Row, Col, Button, Form, FormGroup, Input } from "reactstrap";
import Section from "../section";
import logo from "../images/logo.svg";
import styles from "./contact.module.css";

function ContactWidget(props) {
  return (
    <Section title="Contact" className="contact-container" id="contact">
      <Row>
        <Col xs="12" lg="6">
          <p>We hope to hear from you soon.</p>
          <p>
            <svg
              width={110}
              height={100}
              xmlns="http://www.w3.org/2000/svg"
              xmlnsXlink="http://www.w3.org/1999/xlink"
            >
              <image xlinkHref={logo} width={110} height={100} />
            </svg>
            , mein gang
          </p>
        </Col>
        <Col xs="12" lg="6" className="p-2">
          <Form
            name="Contact"
            method="POST"
            data-netlify="true"
            data-netlify-honeypot="bot-field"
          >
            <input type="hidden" name="bot-field" />
            <FormGroup className={styles.formGroup}>
              <Input
                name="name"
                id="contactName"
                placeholder="Name ..."
                className={styles.input}
              />
            </FormGroup>
            <FormGroup className={styles.formGroup}>
              <Input
                type="email"
                name="email"
                id="contactEmail"
                placeholder="Email address ..."
                className={styles.input}
              />
            </FormGroup>
            <FormGroup className={styles.formGroup}>
              <Input
                name="object"
                id="contactObject"
                placeholder="Object ..."
                className={styles.input}
              />
            </FormGroup>
            <FormGroup className={styles.formGroup}>
              <Input
                type="textarea"
                name="message"
                id="contactMessage"
                className={styles.input}
                placeholder="Message ..."
              />
            </FormGroup>

            <FormGroup className={styles.formButtonGroup} check>
              <Button
                color="default"
                block={true}
                className={styles.submitButton}
              >
                Submit
              </Button>
            </FormGroup>
          </Form>
        </Col>
      </Row>
    </Section>
  );
}

export default ContactWidget;
